import { AdFormat } from 'lib/types/ad-format'

export const isSspFormat = (format: AdFormat) => {
  return [AdFormat.YANDEX_FS, AdFormat.YANDEX_PF, AdFormat.YANDEX_TEXT].indexOf(format) !== -1
}

export const isSspMediaFormat = (format: AdFormat) => {
  return [AdFormat.YANDEX_FS, AdFormat.YANDEX_PF].indexOf(format) !== -1
}

export const isSspTextFormat = (format: AdFormat) => {
  return [AdFormat.YANDEX_TEXT].indexOf(format) !== -1
}
